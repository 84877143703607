<script>
  import { FaHome, FaDesktop, FaPencilRuler } from "svelte-icons/fa";
  import { useLocation } from "svelte-navigator";
  import {
    MdHome,
    MdCode,
    MdDashboard,
    MdMenu,
    MdClose,
  } from "svelte-icons/md";
  import { navigate } from "svelte-navigator";
  import { fade } from "svelte/transition";

  export let currentIndex = 0;
  let hideNav = true;

  const toggleNav = () => {
    hideNav = !hideNav;
  };

  const locations = {
    "/": 0,
    "/dev": 1,
    "/design": 2,
  };

  useLocation().subscribe((data) => {
    currentIndex = locations[data.pathname];
  });

  const changeTab = (loc) => {
    hideNav = true;
    navigate(loc);
  };
</script>

<div class="mobile-menu" on:click={toggleNav}>
  <div class="icon-btn" class:hide={!hideNav}>
    <MdMenu />
  </div>
  <div class="icon-btn" class:hide={hideNav}>
    <MdClose />
  </div>
</div>
<nav class="navbar" class:hide={hideNav} in:fade out:fade>
  <div class="navbar-content">
    <div
      class="navbar-item"
      transition:fade
      class:active={currentIndex === 0}
      on:click={() => changeTab("/")}
    >
      <div class="icon-btn">
        <MdHome />
      </div>
      <h6>Home</h6>
    </div>
    <div
      class="navbar-item"
      transition:fade
      class:active={currentIndex === 1}
      on:click={() => changeTab("/dev")}
    >
      <div class="icon-btn">
        <MdCode />
      </div>
      <h6>Development</h6>
    </div>
    <div
      class="navbar-item"
      transition:fade
      class:active={currentIndex === 2}
      on:click={() => changeTab("/design")}
    >
      <div class="icon-btn">
        <MdDashboard />
      </div>
      <h6>Design and 3D</h6>
    </div>
  </div>
</nav>

<style>
  .mobile-menu {
    position: fixed;
    z-index: 101;
    padding: 10px;
  }

  .navbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 100;
    background: linear-gradient(
      155deg,
      rgba(0, 9, 41, 0.99),
      rgba(4, 31, 95, 0.9)
    );
    backdrop-filter: blur(100px);
  }

  .navbar-content {
    display: flex;
    flex-direction: column;
    gap: 3em;
  }

  .navbar-item {
    display: flex;
    align-items: center;
    gap: 1em;
    padding-inline: 1em;
    cursor: pointer;
  }

  .active {
    border-left: 4px solid white;
    padding-left: calc(1em - 4px);
  }

  h6 {
    font-size: 1em;
    text-transform: uppercase;
    margin: 0;
  }

  .icon-btn {
    height: 40px;
    width: 40px;
  }

  .hide {
    display: none;
  }

  @supports (backdrop-filter: blur(100px)) {
    .navbar {
      background: linear-gradient(
        155deg,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.5)
      );
      backdrop-filter: blur(100px);
    }
  }

  @media (min-width: 50em) {
    .navbar {
      width: min-content;
      display: flex;
      background: none;
      backdrop-filter: none;
    }
    h6 {
      display: none;
    }
    .mobile-menu {
      display: none;
    }
  }
</style>
