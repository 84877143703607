<script>
  import Navbar from "../components/Navbar.svelte";
  import { slide } from "svelte/transition";
  import { onMount } from "svelte/internal";
  import SkillCard from "../components/SkillCard.svelte";
  import GalleryItem from "../components/GalleryItem.svelte";
  let showTransitionIntermediate = true;
  onMount(() => {
    setTimeout(() => {
      showTransitionIntermediate = false;
      // console.log(document.querySelector(".transition-intermediate"));
      // document.querySelector(".transition-intermediate").remove();
    }, 400);
  });

  let skills = [
    "figma",
    "photoshop",
    "illustrator",
    "blender",
    "gimp",
    "krita",
  ];

  let projects = [
    {
      item: {
        image: "/design_showcase/TaskFlowUI_Showcase.jpg",
        title: "TaskFlow UI Concept",
        desc: "UI concept for the TaskFlow mobile application",
        link: "https://www.figma.com/file/uNEfD5NjQAgx5QeZh6GMdg/TaskFlow?node-id=0%3A1",
        tech: ["figma"],
        accent: "hsla(80, 100%, 35%, 0.9)",
      },
      size: "wide",
    },
    {
      item: {
        image: "/design_showcase/MusicaUI_Showcase.jpg",
        title: "Musica UI Concept",
        desc: "UI concept for a Music Streaming mobile application",
        credits: ["https://www.reddit.com/r/fakealbumcovers/"],
        link: "https://www.figma.com/file/TiQT4PJVFjirIOKNEcGl6k/musica",
        tech: ["figma"],
        accent: "hsla(10, 100%, 50%, 0.9)",
      },
      size: "square",
    },
    {
      item: {
        image: "/design_showcase/CoverArt1.png",
        title: "Album Cover Art Concept",
        desc: "Design concept for a music album cover",
        credits: [
          "https://www.pexels.com/photo/white-and-pink-concrete-building-on-green-grass-field-under-white-sky-4526153/",
        ],
        tech: ["photoshop"],
        accent: "hsla(184, 100%, 44%, 0.9)",
      },
      size: "square",
    },
    {
      item: {
        image: "/design_showcase/CoverArt2.png",
        title: "Album Cover Art Concept",
        desc: "Design concept for a music album cover",
        credits: [
          "https://www.pexels.com/photo/people-walking-on-street-during-night-time-5845255/",
        ],
        tech: ["photoshop"],
        accent: "hsla(30, 100%, 50%, 0.9)",
      },
      size: "square",
    },
    {
      item: {
        image: "/design_showcase/CoverArt3.png",
        title: "Album Cover Art Concept",
        desc: "Design concept for a music album cover",
        tech: ["photoshop"],
        accent: "hsla(360, 72%, 61%, 0.9)",
      },
      size: "square",
    },
    {
      item: {
        image: "/design_showcase/CoverArt4.png",
        title: "Album Cover Art Concept",
        desc: "Design concept for a music album cover",
        credits: [
          "https://www.pexels.com/photo/grayscale-photo-of-gas-mask-3621076/",
        ],
        tech: ["photoshop"],
        accent: "hsla(15, 100%, 50%, 0.9)",
      },
      size: "square",
    },
    {
      item: {
        image: "/design_showcase/CoverArt5.png",
        title: "Album Cover Art Concept",
        desc: "Design concept for a music album cover",
        tech: ["photoshop"],
        accent: "hsla(0, 100%, 50%, 0.9)",
      },
      size: "square",
    },
    {
      item: {
        image: "/design_showcase/CoverArt6.png",
        title: "Album Cover Art Concept",
        desc: "Design concept for a music album cover",
        credits: [
          "https://www.pexels.com/photo/arches-architecture-art-baroque-316080/",
          "https://29a.ch/ditherlicious/",
        ],
        tech: ["photoshop"],
        accent: "hsla(0, 0%, 50%, 0.9)",
      },
      size: "square",
    },
    {
      item: {
        image: "/design_showcase/CoverArt7.jpg",
        title: "Album Cover Art Concept",
        desc: "Design concept for a music album cover",
        credits: ["https://www.pexels.com/photo/white-blue-boat-67100/"],
        tech: ["gimp"],
        accent: "hsla(0, 70%, 30%, 0.9)",
      },
      size: "square",
    },
  ];
</script>

<main in:slide out:slide>
  {#if showTransitionIntermediate}
    <div class="transition-intermediate" out:slide />
  {:else}
    <div class="top-gradient" />
    <div class="design-content">
      <div class="skills">
        <h1>Skills</h1>
        <div class="skills-grid">
          {#each skills as skill}
            <SkillCard {skill} />
          {/each}
        </div>
      </div>
      <div class="gallery">
        <h1>Gallery</h1>
        <sub>CLICK AN IMAGE TO EXPAND</sub>
        <div class="gallery-grid">
          {#each projects as p}
            <GalleryItem item={p.item} size={p.size} />
          {/each}
        </div>
      </div>
    </div>
  {/if}
</main>

<style>
  .top-gradient {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 6em;
    z-index: 90;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0)
    );
  }

  .transition-intermediate {
    height: 100vh;
    width: 100vw;
    background: linear-gradient(155deg, rgba(0, 9, 41, 1), rgba(4, 31, 95, 1));
    position: fixed;
    z-index: 91;
  }

  .design-content {
    padding-block: 8em;
    padding-inline: 2em;
  }

  .skills-grid {
    margin-block: 1.5em;
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  .gallery-grid {
    margin-block: 1.5em;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  @media (min-width: 50em) {
    .design-content {
      padding-inline: 15%;
    }

    .gallery-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
</style>
