<script>
  import Navbar from "../components/Navbar.svelte";
  import { MdEmail } from "svelte-icons/md";
  import { FaLinkedinIn, FaGithub, FaChevronDown } from "svelte-icons/fa";
  import { scrollNavigate } from "../util";
  import { slide } from "svelte/transition";
  import { navigate } from "svelte-navigator";

  const currentIndex = 0;
</script>

<main in:slide out:slide>
  <div class="home-area">
    <div class="home-content">
      <h1 class="condensed">Hello there,</h1>
      <h1 class="larger">
        I'm <span class="highlight">Aaryak!</span>
      </h1>
      <p>
        I am an IT student in my third year, and an aspiring <strong
          >developer</strong
        >
        and <strong>designer</strong>. Currently exploring the world of app
        development and web development. I am a contributor in development
        projects and also have some experience in graphic design.
      </p>
      <div class="profiles">
        <h2>Find Me On</h2>
        <div class="profile-links">
          <a href="mailto:aaryakdshah@gmail.com"
            ><div class="link-icon"><MdEmail /></div>
            <div class="link-name">Mail</div></a
          >
          <a href="https://www.linkedin.com/in/aaryak-shah/"
            ><div class="link-icon"><FaLinkedinIn /></div>
            <div class="link-name">LinkedIn</div></a
          >
          <a href="https://github.com/aaryak-shah"
            ><div class="link-icon"><FaGithub /></div>
            <div class="link-name">Github</div></a
          >
        </div>
      </div>
    </div>
    <div class="down-indicator" on:click={() => navigate("/dev")}>
      <FaChevronDown />
    </div>
  </div>
</main>

<style>
  .home-area {
    height: 100vh;
    width: 100vw;
    background-image: url("/abstract_dark.jpg");
    /* background-size: max(200vh, 100vw); */
    display: flex;
    justify-content: center;
    background-size: cover;
    background-position: 65%;
  }
  .home-content {
    padding-inline: 2em;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .larger {
    font-size: 3em;
    padding-block: 0.3em;
  }
  .highlight {
    padding-inline: 0.1em;
    background-color: white;
    color: black;
    font-weight: bold;
    text-align: center; /* Center text */
    mix-blend-mode: screen; /* This makes the cutout text possible */
  }

  strong {
    text-decoration: underline 2px;
  }

  .profile-links {
    /* display: flex; */
    gap: 2em;
    padding-block: 0.4em;
  }

  .link-icon {
    width: 1.2em;
    height: 1.2em;
  }

  a {
    color: white;
    display: inline-flex;
    gap: 6px;
    font-size: 1.2em;
    text-decoration: none;
    margin-right: 1em;
  }
  a:hover,
  a:focus {
    text-decoration: underline 1px;
  }

  .down-indicator {
    height: 30px;
    width: 30px;
    position: absolute;
    bottom: 2em;
    cursor: pointer;
    padding: 10px;
    border-radius: 100px;
  }
  .down-indicator:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  @media (min-width: 50em) {
    .home-content {
      max-width: 65ch;
      margin-inline: auto;
      margin-left: 15%;
    }
  }
</style>
