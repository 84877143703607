<script>
  import { FaLink } from "svelte-icons/fa";

  import { MdClose, MdOpenInBrowser } from "svelte-icons/md";
  import { onDestroy, onMount } from "svelte/internal";
  export let item = {
    tech: [],
    credits: [],
  };
  export let showViewer = false;

  onMount(() => {
    document.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        showViewer = false;
      }
    });
  });

  onDestroy(() => {
    document.body.style.overflow = "auto";
  });

  $: {
    if (showViewer) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }
</script>

{#if showViewer}
  <div class="viewer" class:visible={showViewer}>
    <div class="icon-btn icon-btn-bg">
      <MdClose />
    </div>
    <div class="icon-btn" on:click={() => (showViewer = false)}>
      <MdClose />
    </div>
    <div class="image">
      <img src={item.image} alt="" />
    </div>
    <div class="details">
      <sub>
        {#each item.tech as tech}
          {tech}
        {/each}
      </sub>
      <h1 style="color: {item.accent};">{item.title}</h1>
      <p>{item.desc}</p>
      <div class="tech">
        {#each item.tech as tech}
          <div class="tech-item" />
        {/each}
      </div>
      {#if item.link}
        <a href={item.link} class="link">
          <div class="icon">
            <FaLink />
          </div>
          <h5>FIND THE PROJECT HERE</h5>
        </a>
      {/if}
      {#if item.credits?.length}
        <div class="credits">
          <h3>Asset Credits:</h3>
          {#each item.credits as credit}
            <a class="credit-item" href={credit}>
              <div class="icon"><MdOpenInBrowser /></div>
              <h5>{new URL(credit).hostname}</h5>
            </a>
          {/each}
        </div>
      {/if}
    </div>
  </div>
{/if}

<style>
  .viewer {
    background-color: black;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    z-index: 200;
    overflow-y: auto;
  }

  .icon-btn {
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: fixed;
    top: 1em;
    right: 1em;
  }

  .icon-btn-bg {
    color: black;
    filter: blur(6px);
  }

  .details {
    padding: 1em;
  }

  img {
    object-fit: contain;
    width: 100%;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .link,
  .credit-item {
    background: white;
    color: black;
    text-decoration: none;
    padding-block: 0.5em;
    padding-inline: 1em;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    gap: 0.5em;
    margin-right: 1em;
    margin-block: 1em;
    transition: 0.4s ease;
  }

  h3 {
    margin-top: 1em;
  }

  @media (min-width: 60em) {
    img {
      height: 100vh;
    }
    .viewer {
      flex-direction: row;
      overflow: hidden;
    }
    .image {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .details {
      flex: 1;
      padding-block: 8em;
      padding-inline: 2em;
      overflow: auto;
    }
  }
</style>
