<script>
  export let skill = "";

  let skillMap = {
    svelte: {
      image: "/logos/Svelte_Logo.png",
      color: "#ff0000",
    },
    react: {
      image: "/logos/React_Logo.png",
      color: "#000000",
    },
    flutter: {
      image: "/logos/Flutter_Logo.png",
      color: "#000000",
    },
    node: {
      image: "/logos/Node_Logo.png",
      color: "#000000",
    },
    express: {
      image: "/logos/Express_Logo.png",
      color: "#000000",
    },
    graphql: {
      image: "/logos/GraphQL_Logo.png",
      color: "#000000",
    },
    mongodb: {
      image: "/logos/MongoDB_Logo.png",
      color: "#000000",
    },
    sql: {
      image: "/logos/SQL_Logo.png",
      color: "#000000",
    },
    "HTML/CSS": {
      image: "/logos/HTML5_Logo.png",
      color: "#000000",
    },
    javascript: {
      image: "/logos/JavaScript_Logo.png",
      color: "#000000",
    },
    python: {
      image: "/logos/Python_Logo.png",
      color: "#000000",
    },
    "c++": {
      image: "/logos/C++_Logo.png",
      color: "#000000",
    },
    photoshop: {
      image: "/logos/Photoshop_Logo.png",
    },
    figma: {
      image: "/logos/Figma_Logo.png",
    },
    illustrator: {
      image: "/logos/Illustrator_Logo.png",
    },
    blender: {
      image: "/logos/Blender_Logo.png",
    },
    gimp: {
      image: "/logos/Gimp_Logo.png",
    },
    krita: {
      image: "/logos/Krita_Logo.png",
    },
  };
</script>

<div class="card">
  <div class="skill-icon">
    <img src={skillMap[skill].image} alt="" />
  </div>
  <h5 class="skill-name">{skill}</h5>
</div>

<style>
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    gap: 0.4em;
    /* border: 1px solid red; */
  }

  img {
    height: 60px;
    /* width: 40px; */
  }

  h5 {
    text-transform: uppercase;
  }

  @media (min-width: 50em) {
  }
</style>
