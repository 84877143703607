<script>
  import { FaGithub, FaLink } from "svelte-icons/fa";
  export let project = {};
  export let index = 0;
</script>

<div class="card" class:reverse-flex={index % 2 !== 0}>
  <div class="card-image"><img src={project.image} alt="" /></div>
  <div class="card-content">
    <h2>{project.title}</h2>
    <div class="links">
      {#if project.github}
        <a class="btn" href={project.github}>
          <div class="icon-button"><FaGithub /></div>
          <h5>GITHUB</h5>
        </a>
      {/if}
      {#if project.direct}
        <a class="btn" href={project.direct}>
          <div class="icon-button"><FaLink /></div>
          <h5>DIRECT LINK</h5>
        </a>
      {/if}
    </div>
    <p>{project.desc}</p>
    <div class="tech-list">
      {#each project.tech as tech}
        <div class="tech-chip">
          <h5>{tech}</h5>
        </div>
      {/each}
    </div>
  </div>
</div>

<style>
  .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    gap: 1em;
  }

  .card-image {
    aspect-ratio: calc(16 / 9);
    /* height: 100%; */
    width: 100%;
    border-radius: 5px;
    /* border: 2px solid white; */
    background-color: #252525;
    overflow: hidden;
  }

  img {
    height: 100%;
  }

  .card-content {
  }

  .reverse-flex {
    /* flex-direction: row-reverse; */
  }

  .tech-chip {
    display: inline-block;
    border: 1px solid white;
    border-radius: 100px;
    padding-inline: 1em;
    padding-block: 0.5em;
    margin-right: 0.6em;
    margin-bottom: 0.6em;
  }

  .btn {
    background: white;
    color: black;
    text-decoration: none;
    padding-block: 0.5em;
    padding-inline: 1em;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    gap: 0.5em;
    margin-right: 1em;
    margin-top: 1em;
    transition: 0.4s ease;
  }

  .btn:hover {
    background: linear-gradient(155deg, rgba(0, 9, 41, 1), rgba(4, 31, 95, 1));
    color: white;
  }

  .icon-button {
    height: 20px;
    width: 20px;
  }

  h5 {
    text-transform: uppercase;
  }
</style>
