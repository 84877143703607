<script>
  import { FaLink } from "svelte-icons/fa";
  import ImageViewer from "./ImageViewer.svelte";
  export let item = {
    image: "/dev_showcase/FoodEx_Showcase.png",
    title: "FoodEx UI Concept",
    desc: "UI design concept for the FoodEx project",
    tech: ["fg"],
    link: "",
    accent: "hsla(40, 100%, 50%, 0.9)",
  };
  export let size = "square";

  let showViewer = false;

  const toggleViewer = () => {
    showViewer = !showViewer;
  };
</script>

<ImageViewer bind:showViewer {item} />

<div class={`item item-${size}`} on:click={toggleViewer}>
  <img src={item.image} alt={item.title} />
  <div class="info" style="background-color: {item.accent}">
    <div class="tech">
      {#each item.tech as tech}
        <div class="tech-item">
          <h4>{tech}</h4>
        </div>
      {/each}
    </div>
    <div class="info-text">
      <h2>{item.title}</h2>
      <p>{item.desc}</p>
      {#if item.link}
        <a class="link" href={item.link}>
          <div class="icon"><FaLink /></div>
          <h5>LINK</h5>
        </a>
      {/if}
    </div>
  </div>
</div>

<style>
  .item {
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;
    margin: 0;
    /* cursor: pointer; */
    position: relative;
    background-color: #252525;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  p {
    margin: 0;
  }

  h4 {
    text-transform: uppercase;
  }

  .tech {
    display: flex;
  }

  .tech-item {
    display: inline-flex;
  }

  .tech-item::after {
    content: ", ";
  }

  .tech-item:last-child:after {
    content: "";
  }

  .info {
    padding: 1em;
    height: calc(100% - 2em);
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    transition: all 0.4s ease;
    z-index: 70;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .item:hover > .info {
    /* opacity: 100%; */
    top: 0;
  }

  img:focus {
    border: 2px solid red;
  }

  .item-square {
    grid-column: span 1;
    aspect-ratio: 1;
  }

  .item-wide {
    grid-column: span 2;
    aspect-ratio: calc(16 / 9);
  }

  .item-long {
    grid-row: span 2;
    aspect-ratio: 0.5;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .link {
    background: white;
    color: black;
    text-decoration: none;
    padding-block: 0.5em;
    padding-inline: 1em;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    gap: 0.5em;
    margin-right: 1em;
    margin-top: 1em;
    transition: 0.4s ease;
  }
</style>
