<script>
  import Navbar from "../components/Navbar.svelte";
  import { slide } from "svelte/transition";
  import { onMount } from "svelte/internal";
  import SkillCard from "../components/SkillCard.svelte";
  import ProjectCard from "../components/ProjectCard.svelte";

  import { FaChevronDown, FaChevronUp } from "svelte-icons/fa";

  const currentIndex = 1;

  let showTransitionIntermediate = true;
  onMount(() => {
    setTimeout(() => {
      showTransitionIntermediate = false;
      // console.log(document.querySelector(".transition-intermediate"));
      // document.querySelector(".transition-intermediate").remove();
    }, 400);
  });

  let skills = [
    "svelte",
    "react",
    "flutter",
    "node",
    "express",
    "graphql",
    "mongodb",
    "sql",
    "HTML/CSS",
    "javascript",
    "python",
    "c++",
  ];

  let projects = [
    {
      title: "ApnaClassroom",
      desc: "Apna Classroom is a specialized online classroom system designed to work inside the IIITM domain. It provides a variety of features such as batch-wise automatic enrollmennt for students, an integrated VC platform, assignment submissions and result summaries, among others. A public-domain fork of this project will be created soon.",
      image: "/dev_showcase/ApnaClassroom_Showcase.png",
      tech: [
        "React",
        "Redux",
        "Nodejs",
        "Expressjs",
        "Mongodb",
        "GridFS",
        "Web Dev",
      ],
      github: "https://github.com/Ashes-Mondal/apna-classroom",
    },
    {
      title: "git-init-FOSS",
      desc: "The event information and statistics website for the AASF git-init-FOSS event that was held in October 2021. It features a showcase of applicable projects as well as an event leaderboard and a GitHub statistics display.",
      image: "/dev_showcase/GitInitFOSS_Showcase.png",
      tech: ["Svelte", "Web Dev"],
      github: "https://github.com/aaryak-shah/git-init-FOSS",
      direct: "https://git-init-foss.vercel.app/",
    },
    {
      title: "CodeBox",
      desc: "CodeBox is a remote code executor website that can run python, node and c++ code from any browser. It uses docker to sandbox user code and requests for safe code execution. It also allows sharing sessions, allowing other users to observe your code live.",
      image: "/dev_showcase/CodeBox_Showcase.png",
      tech: ["Docker", "React", "Nodejs", "Expressjs", "Socket.io", "Web Dev"],
      github: "https://github.com/nafees87n/codebox",
      direct: "http://codebox.herokuapp.com/",
    },
    {
      title: "TaskFlow",
      desc: "[In Develeopment] TaskFlow is a productivity app for your daily tasks, goals and projects. You can track your time utilization throughout the day. Create an account, or use the app as a guest to get started. The app has a statistics section to visualize your data and provide insights about it. Data analysis is performed using a Flask API.",
      image: "/dev_showcase/TaskFlow_Showcase.png",
      tech: ["Flutter", "Firebase", "Flask", "App Dev"],
      github: "https://github.com/aaryak-shah/task_flow",
    },
    {
      title: "Route Finder",
      desc: "Route Finder is an optimal train route generator using a modified A* Search algorithm. The algorithm's heuristic adds an extra consideration for waiting-time at train stations.",
      image: "/dev_showcase/RouteFinder_Showcase.png",
      tech: ["Python", "Graph Search", "CLI"],
      github: "https://github.com/aaryak-shah/route-finder",
    },
    {
      title: "FoodEx",
      desc: "FoodEx is a food delivery, ordering and rating platform. The website allows for two kinds of users - Customers and Restaurants. Customers are provided with a catalogue of restaurants and dishes, and a cart to order food. Customers can also Review their past orders. Restaurants are provided a dashboard to manage their delivery orders and to view feedback.",
      image: "/dev_showcase/FoodEx_Showcase.png",
      tech: ["HTML", "CSS", "EJS", "Nodejs", "Expressjs", "MySQL", "Web Dev"],
      github: "https://github.com/hs2361/foodex-project",
      direct: "https://foodex-webkriti.herokuapp.com/",
    },
  ];
</script>

<main in:slide out:slide>
  {#if showTransitionIntermediate}
    <div class="transition-intermediate" out:slide />
  {:else}
    <div class="top-gradient" />
    <div class="dev-content">
      <div class="projects">
        <h1>Development Projects</h1>
        <div class="projects-grid">
          {#each projects as project}
            <ProjectCard {project} />
          {/each}
        </div>
      </div>
      <div class="skills">
        <h1>Skills and Technologies</h1>
        <div class="skills-grid">
          {#each skills as skill}
            <SkillCard {skill} />
          {/each}
        </div>
      </div>
    </div>
  {/if}
</main>

<style>
  .top-gradient {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 6em;
    z-index: 90;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0)
    );
  }

  /* main {
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  .dev-content {
    padding-block: 8em;
    padding-inline: 2em;
  }

  .transition-intermediate {
    height: 100vh;
    width: 100vw;
    background: linear-gradient(155deg, rgba(0, 9, 41, 1), rgba(4, 31, 95, 1));
    position: fixed;
    z-index: 92;
  }

  .skills-grid {
    margin-block: 1.5em;
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  .projects-grid {
    margin-block: 1.5em;
    display: grid;
    row-gap: 3em;
    column-gap: 5em;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .skills {
    margin-top: 5em;
  }

  @media (min-width: 50em) {
    .dev-content {
      padding-inline: 15%;
    }
    .projects-grid {
      margin-block: 1.5em;
      display: grid;
      row-gap: 3em;
      column-gap: 5em;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
  }

  .hide {
    display: none;
  }
</style>
