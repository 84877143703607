<script>
  import { Router, Route } from "svelte-navigator";
  import Home from "./routes/Home.svelte";
  import Dev from "./routes/Dev.svelte";
  import Design from "./routes/Design.svelte";
  import Navbar from "./components/Navbar.svelte";
  let currentIndex = 0;
</script>

<main />
<Router primary={false}>
  <Navbar {currentIndex} />
  <Route path="/" component={Home} />
  <Route path="/dev" component={Dev} />
  <Route path="/design" component={Design} />
</Router>
